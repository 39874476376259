@use "../abstracts/media-query";
@use "../abstracts/functions";
@use "../abstracts/colors";
@use "../abstracts/fonts";
@use "../abstracts/z-index";

.o-chatbot {
    $o-chatbot: &;
    background-color: rgba(0, 0, 0, 0.635);

    &__chat-wrapper {
        background-color: #F1F7FF;
        display: block;
        max-width: 100%;
        width: 95%;
        height: 95vh;
        padding: 0;
        justify-content: flex-start;
        @include media-query.breakpoint(smallg) {
            width: 70%;
        }
        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background: #fff;
        }
        
        &::-webkit-scrollbar-thumb:hover {
            background: #DFC3FF;
        }
    }

    &__chat-header {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    &__btnchat{
        position: relative;
        padding: 15px;
        display: flex;
        gap: 15px;
        align-items: center;
        justify-content: center;
        width: 100%;
        box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
    }

    &__container {
        width: 100%;
        position: relative;
        /* max-width: 600px; */
        height: calc(100% - 132px);
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, .1);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__messages {
        flex-grow: 1;
        overflow-y: auto;
        padding: 20px;
    }

    &__title{
        font-size: 20px;
        font-weight: 600;
        text-transform: fonts.$capitalize;
        color: #033C81;
        margin-bottom: 0;
        @include media-query.breakpoint(smallg) {
            margin-bottom: 0 !important;
        }
    }
    &__chatclose{
        color: #000;
        transform: rotate(45deg);
        position: absolute;
        right: 10px;
        top: 15px;
        font-size: 30px;
        width: 40px;
        height: 40px;
    }

    &__button {
        
        width: fit-content;
        height: functions.torem(40px);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: fonts.$uppercase;
        padding: 0 functions.torem(10px);
        font-size: functions.torem(14px);
        font-weight: fonts.$font-weight--bolder;
        @include media-query.breakpoint(smallg) {
            min-width: functions.torem(170px);
        }

        &--speak {
            border: functions.torem(1px) solid #C377FC;
            background-color: #C377FC;
            color: #fff;
            &:hover{
                background-color: #7500CE;
                color: colors.$white;
            }
        }
        &--stop {
            border: functions.torem(1px) solid #787878;
            color: #787878;
            &:hover{
                background-color: #787878;
                color: colors.$white;
            }
        }
    }


    

}

.loader {
    display: inline-block;
    width: 60px;
    aspect-ratio: 2;
    --_g: no-repeat radial-gradient(circle closest-side,#ffffff 90%,#ffffff00);
    background: 
        var(--_g) 0%   50%,
        var(--_g) 50%  50%,
        var(--_g) 100% 50%;
    background-size: calc(100%/3) 50%;
    animation: l3 1s infinite linear;
}
@keyframes l3 {
    20%{background-position:0%   0%, 50%  50%,100%  50%}
    40%{background-position:0% 100%, 50%   0%,100%  50%}
    60%{background-position:0%  50%, 50% 100%,100%   0%}
    80%{background-position:0%  50%, 50%  50%,100% 100%}
}

.js-speak-icon {
    display: none;
}

.speak-mic-animation {
    position: absolute;
    display: none;
    left: 20px;
    top: 15px;
    border-radius: 100%;
    border: #c4c4c4 2px solid;
    width: 40px;
    height: 40px;
    overflow: hidden;
    
    img {
        width: 50px;
        margin-bottom: 0;
    }
}

.chat-message {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &.llm {
        align-items: flex-start;
    }

    p {
        margin: 5px 0;
        padding: 20px;
        border-radius: 15px;
        font-size: 14px;
    }

    .user-name {
        font-weight: bold;
        margin-bottom: 5px;
        font-size: 12px; 
    }

    .user-text {
        background-color: #cee3ff;
        text-align: right;
        position: relative;

        &:after {
            content: "";
            position: absolute;
            right: 0;
            bottom: -11px;
            width: 0px;
            height: 0px;
            border-style: solid;
            border-width: 0 20px 20px 0;
            border-color: transparent #cee3ff transparent transparent;
            transform: rotate(0deg);  
        }
    }

    .llm-text {
        background-color: #f3e3ff;
        text-align: left;
        position: relative;
        padding-left: 60px;

        &:before {
            content: "";
            background: url(../images/useplan1.svg) no-repeat;
            width: 36px;
            height: 50px;
            position: absolute;
            top: 8px;
            left: 15px;
        }

        &:after {
            position: absolute;
            content: "";
            left: 0;
            bottom: -11px;
            width: 0px;
            height: 0px;
            border-style: solid;
            border-width: 20px 20px 0 0;
            border-color: #F3E3FF transparent transparent transparent;
            transform: rotate(0deg);
        }
    }

}

.float-chatbot-mobile {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999;
}

.disable-pointer-events {
    pointer-events: none;
}

.no-plans {
    position: relative;
    z-index: 1;
    width: 100%;
    text-align: center;
    max-width: 100%;
    padding: functions.torem(20px);
    background-color: colors.$white;
    border: functions.torem(1px) solid colors.$border-color1;
    border-radius: 12px;
    box-shadow: 0 functions.torem(4px) functions.torem(4px) 0 rgba(194, 213, 249, .5);
    transition: all .5s ease-out;
    margin: 7px 0;

    .icon {
        font-size: 50px;
        margin-bottom: 15px;
        color: #c0dcff;
    }
    h2 {
        margin-bottom: 15px;
        font-size: 22px;
        font-weight: 600;
    }
    p {
        margin-bottom: 15px;
    }
}

.message-display-style 
{
    position: fixed;
    right: -300px;
    width: 300px;
    top: 100px;
    z-index: 9;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    background-color: #fff;
    padding: 25px 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    animation: slide-in-out 7s ease-in-out forwards;
    &:before {
        content: "";
        position: absolute;
        width: 5px;
        height: 100%;
        background-color: #000;
        top: 0;
        left: 0;
    }

    &.error-message {
        &:before {
            background-color: rgb(186, 0, 0);
        }
    }
}

/* Keyframes for sliding in and out */
@keyframes slide-in-out {
    /* Start hidden */
    0% {
      right: -300px; /* Off-screen */
      opacity: 0;
    }
    /* Slide in after 2 seconds */
    20% {
      right: 0px; /* On-screen */
      opacity: 1;
    }
    /* Stay visible */
    70% {
      right: 0px; /* Stay on-screen */
      opacity: 1;
    }
    /* Slide out after 5 seconds */
    100% {
      right: -300px; /* Back off-screen */
      opacity: 0;
    }
  }