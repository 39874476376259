@use "../abstracts/media-query";
@use "../abstracts/functions";
@use "../abstracts/colors";
@use "../abstracts/fonts";
.o-login {
  $o-login: &;
  min-height: 100vh;
  height: auto;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(218, 231, 254, 1) 100%
  );
  @include media-query.breakpoint(hmedium) {
    height: 100vh;
    overflow: hidden;
  }
  &__logo {
    margin: 23px 0 0;
    display: inline-block;
    @include media-query.breakpoint(smallg) {
      margin: 46px 0 0;
    }
  }

  &__boxarea {
    position: relative;
    z-index: 1;
    width: 100%;
    padding: 40px 20px 20px;
    height: fit-content;
    overflow: hidden;
    @include media-query.breakpoint(hmedium) {
      padding: 0px 20px 20px 20px;
      overflow: visible;
      min-height: 100vh;
    }
  }

  &__leftimage {
    position: relative;
    float: right;
    display: none;
    @include media-query.breakpoint(smallg) {
      display: block;
    }
    @include media-query.breakpoint(large) {
      top: 120px;
    }
    // &--signup {
    //   top: inherit;
    // }
  }

  &__leftimagemain {
    position: relative;
  }

  &__leftimageblue {
    position: absolute;
    top: -20px;
    right: -40px;
  }

  &__leftimagepink {
    position: absolute;
    bottom: -20px;
    z-index: 1;
  }

  &__rightbox {
    position: relative;
    max-width: 786px;
    width: 100%;
    background-color: #eff4fb;
    border-radius: 20px;
    padding: 15px;
    @include media-query.breakpoint(large) {
      padding: 30px;
    }
  }

  &__rightboxpink {
    position: absolute;
    top: -90px;
    left: -120px;
    opacity: 0.12;
    display: none;
    @include media-query.breakpoint(smallg) {
      display: block;
    }
  }

  &__rightboxblue {
    position: absolute;
    bottom: -70%;
    left: -40%;
    display: none;
    @include media-query.breakpoint(smallg) {
      display: block;
    }
  }

  &__rightboxtittle {
    font-size: 20px;
    line-height: normal;
    font-weight: fonts.$font-weight--normal;
    text-transform: uppercase;
    color: colors.$body-color;
    margin-bottom: 20px;
    @include media-query.breakpoint(smallg) {
      font-size: 24px;
      margin-bottom: 8px;
    }
    @include media-query.breakpoint(extralarge) {
      font-size: 32px;
      line-height: 44px;
      margin-bottom: 20px;
    }
    
  }

  &__radioarea {
    margin-bottom: 20px;
    @include media-query.breakpoint(smallg) {
      margin-bottom: 10px;
    }
    @include media-query.breakpoint(extralarge) {
      margin-bottom: 20px;
    }
  }

  &__radiobox {
    cursor: pointer;
    position: relative;
    padding: 10px 10px 10px 30px;
    display: flex;
    border-radius: 10px;
    width: auto;
    margin-right: 10px;
    transition: all 0.5s ease-out;
    &:hover {
      background-color: #c57efc20;
    }
    @include media-query.breakpoint(smallg) {
      max-width: 236px;
      width: 48%;
      padding: 12px 10px 12px 50px;
    }
    .border {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border: none !important;
      &:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 10px;
        border: 1px solid #A0C4F1;
      }
    }
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      &:checked {
        ~ .checkmark {
          background-color: #C67EFC;
          border-color: #C67EFC;
          svg {
            display: block;
          }
        }
        ~ .border {
          &:before {
            border: 1px solid #3967A2;
          }
        }
      }
    }
    .checkmark {
      position: absolute;
      top: 6px;
      left: 7px;
      height: 16px;
      width: 16px;
      background-color: transparent;
      border: 1px solid #3967A2;
      border-radius: 50%;
      svg {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        margin: auto;
        transform: translateY(-50%);
      }
    }
  }
  &__radioboximg {
    margin-right: 10px;
    @include media-query.breakpoint(smallg) {
      margin-right: 13px;
    }
  }

  &__radioboxtext {
    font-family: fonts.$font-family--body;
    color: colors.$black;
    font-size: 12px;
    line-height: oaut0;
    @include media-query.breakpoint(smallg) {
      font-size: 16px;
      line-height: 21px;
    }
  }

  &__formgroup {
    display: block;
    width: 100%;
    @include media-query.breakpoint(smallg) {
      display: flex;
      gap: 10px;
      flex-flow: wrap-reverse;
    }
    .form-controls {
      width: 100%;
      @include media-query.breakpoint(smallg) {
        width: 49%;
      }
      &--full{
        @include media-query.breakpoint(smallg) {
          width: 100%;
        }
      }
      &__textarea{
        @include media-query.breakpoint(smallg) {
          width: 100%;
        }
      }
    }
    .password{
      width: 100%;
      @include media-query.breakpoint(smallg) {
        width: 49%;
      }
    }
    .pwd-field {
      width: 100%;
      position: relative;
      @include media-query.breakpoint(smallg) {
        // width: 49%;
      }

      .form-controls {
        width: 100%;
        @include media-query.breakpoint(smallg) {
          width: 49%;
        }
        // -webkit-text-security: disc;
      }
      .eye {
        position: absolute;
        right: 20px;
        top: 42%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        z-index: 1;
        @include media-query.breakpoint(smallg) {
          top: 50%;
        }
        @include media-query.breakpoint(medium) {
          top: 42%;
        }
        .hide {
          display: none;
        }
        .show {
          display: block;
        }
      }
    }

    &--dropdown {
      flex-direction: column;
      flex-flow: column;
    }
  }

  &__formgroup-individual {
    flex-flow: column;
  }

  &__choose-tanent {
    display: flex;
    flex-direction: column;
    width: 100%;
    label {
      margin-bottom: 5px;
    }
    select {
      margin-bottom: 5px;
    }
  }


  &__form-wrapper {
    // display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &__btn-groups {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    justify-content: center;
    @include media-query.breakpoint(smallg) {
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: row;
    }
  }
  &__bottomtext {
    font-family: fonts.$font-family--body;
    margin-top: 20px;
    font-size: 16px;
    line-height: 24px;
    color: colors.$black;
    text-align: center;
    @include media-query.breakpoint(smallg) {
      text-align: left;
      margin-top: 10px;
    }
    @include media-query.breakpoint(extralarge) {
      margin-top: 15px;
    }
  }
  .btn-default {
    display: block;
    margin: auto;
    @include media-query.breakpoint(smallg) {
      margin: 0;
    }
  }
  &__bottomlink {
    color: #C67EFC;
    text-decoration: none;
  }
}



.form-section {
  display: none;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
.form-section.active {
  display: block;
  opacity: 1;
}
.eye{
  position: absolute;
  right: 10px;
  top: 23px;
}
.password{
  position: relative;
  width: 100%;
  display: block;
  .form-controls{
      -webkit-text-security: disc;
      @include media-query.breakpoint(smallg) {
          width: 100%;
      }
  }
  .show{
    display: none;
  }
  .hide{
    display: block;
  }
  &.showPass{
      .form-controls{
          -webkit-text-security: none;
      }
      .show{
          display: block;
      }
      .hide{
          display: none;
      } 
  }
}
.header{
  .col-md-12{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header__hamburg{
    margin: 23px 0 0;
  }
}