@use "../abstracts/media-query";
@use "../abstracts/functions";
@use "../abstracts/colors";
@use "../abstracts/fonts";
@use "../abstracts/z-index";
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Poppins, sans-serif;
}
.bg-dashboard {
  background: rgb(166, 159, 241);
  background: linear-gradient(
    0deg,
    rgba(167, 162, 241, 1) 0%,
    rgba(166, 159, 241, 1) 50%,
    rgba(166, 159, 241, 1) 100%
  );
  .container-fluid {
    max-width: 1308px;
  }
}
.o-dasboard {
  $o-dashboard: &;

  position: relative;
  z-index: 1;
  &.menu-open {
    z-index: 9;
  }
  label {
    font-size: 14px;
    color: #0052b4;
    margin-bottom: functions.torem(2px);
  }
  p {
    color: colors.$left-lick-color;
    font-size: functions.torem(16px);
  }
  &__spacing {
    padding: 0;
    @include media-query.breakpoint(extralarge) {
      padding: 0;
    }
  }
  &__aphalfuser {
    display: inline-block;
  }
  &__book-confirmed {
    text-align: center;
    margin-top: 20px;
    @include media-query.breakpoint(medium) {
      margin-top: 50px;
    }
    h3 {
      color: #033c81;
      font-size: 30px;
      font-weight: 600;
      margin-top: 20px;
      margin-bottom: 10px;
    }
    h6 {
      color: #033c81;
      margin-bottom: 15px;
      font-size: 20px;
    }
    p {
      font-size: 16px;
      margin-bottom: 30px;
      margin-bottom: 15px;
    }
  }
  &__apartbtn--solid {
    background-color: #7500ce;
    color: #fff !important;
    &:hover {
      background-color: #3f006f !important;
    }
  }
  &__success-btn {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 10px;
    justify-content: center;
    @include media-query.breakpoint(medium) {
      flex-direction: row;
    }
  }
  &__treatment-details {
   
    @include media-query.breakpoint(medium) {
      overflow: auto;
      height: 430px;
    }
    img {
      width: 150px;
      margin-bottom: 15px;
      @include media-query.breakpoint(medium) {
        width: 150px;
        float: left;
        margin-right: 20px;
        margin-bottom: 20px;
      }
    }
  }
  &__booktypelist--overflow {
    @include media-query.breakpoint(medium) {
      overflow: auto;
      height: 470px;
    }
  }
  &__elipsis-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  &__bgrow {
    background-color: colors.$white;
    border-radius: 10px;
    margin-bottom: 8px;
    padding: 12px 5px;
  }
  &__container {
    padding: 0;
    @include media-query.breakpoint(small) {
      padding: 0 15px;
    }
  }
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0;
    transition: all 0.45s ease-in-out;
    pointer-events: none;
    visibility: hidden;

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }
  &__left {
    max-width: 234px;
    width: auto;
    @include media-query.breakpoint(small) {
      width: 45%;
      margin-right: 12px;
    }
    @include media-query.breakpoint(ipad) {
      width: 35%;
    }
  }
  &__right {
    max-width: 100%;
    width: 100%;
    @include media-query.breakpoint(small) {
      width: 66%;
    }
    @include media-query.breakpoint(medium) {
      width: 73%;
    }
    @include media-query.breakpoint(hmedium) {
      width: 78%;
    }
    @include media-query.breakpoint(large) {
      max-width: 1032px;
      width: 79.7%;
    }
    @include media-query.breakpoint(elarge) {
      width: 82%;
    }
  }
  &__flexwrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  &__leftbar {
    position: fixed;
    right: 0;
    top: 0;
    background-color: colors.$white;
    padding: functions.torem(15px);
    border-radius: functions.torem(20px);
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s linear;
    width: 100%;
    overflow: hidden;
    z-index: 10;
    width: functions.torem(270px);
    height: 100%;
    @include media-query.breakpoint(smallg) {
      position: relative;
      right: inherit;
      top: inherit;
      height: functions.torem(587px);
      visibility: visible;
      opacity: 1;
      padding: functions.torem(13px);
      width: 100%;
    }
    @include media-query.breakpoint(extralarge) {
      padding: functions.torem(14px);
    }
    &--active {
      visibility: visible;
      opacity: 1;
      box-shadow: 0 0 functions.torem(20px) functions.torem(8px)
        rgba(0, 0, 0, 0.4);
      border-radius: functions.torem(5px) 0 0 functions.torem(5px);
    }
  }
  &__leftlist {
    position: relative;
    &--patient {
      #{$o-dashboard}__leftitems {
        &:hover {
          background-color: #e6e3ff;
        }
        &.js-active {
          background-color: #e6e3ff;
        }
      }
    }
  }
  &__leftitem {
    margin-bottom: functions.torem(10px);
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__leftitems {
    @include fonts.font-size--normal;
    color: colors.$left-lick-color;
    padding: functions.torem(16px) functions.torem(10px);
    border-radius: functions.torem(4px);
    width: 100%;
    display: block;
    text-decoration: none;
    &:hover {
      color: colors.$body-color;
      background-color: #f8edff;
    }
    &.js-active {
      color: colors.$body-color;
      font-weight: fonts.$font-weight--bold;
      background-color: #f8edff;
    }
  }
  &__itemscenter {
    display: inline-block;
    text-align: center;
    width: functions.torem(22px);
    margin-right: functions.torem(10px);
  }

  &__rightbar {
    background-color: colors.$white;
    border-radius: functions.torem(20px);
    overflow: hidden;
    padding: 20px 15px;
    background-color: #f1f7ff;
    @include media-query.breakpoint(small) {
      padding: 20px;
    }
    @include media-query.breakpoint(large) {
      padding: 30px;
    }
    @include media-query.breakpoint(ipad) {
      height: functions.torem(587px);
    }

    &--doctordash {
      background: #fbe6fe;
      @include media-query.breakpoint(smallg) {
        background: #fbe6fe url(../images/doctor-back.png) no-repeat center
          right;
        background-size: cover;
      }
    }
    &--doctorappoint {
      background: colors.$white;
      @include media-query.breakpoint(smallg) {
        padding: 20px;
        background: colors.$white url(../images/doctor-appoint.png) no-repeat
          bottom right;
        background-size: cover;
      }
      #{$o-dashboard}__rightheading {
        padding-bottom: 0;
      }
      #{$o-dashboard}__rightbody {
        padding-bottom: 0;
      }
      // #{$o-dashboard}__overflowauto{
      //     padding-bottom: 80px;
      // }
    }
    &--doctordetails {
      background: #fff;
      @include media-query.breakpoint(smallg) {
        background: #fff url(../images/doctor-details-back.png) no-repeat bottom
          right;
        background-size: cover;
      }
      #{$o-dashboard}__rightheading {
        padding: 0;
      }
      #{$o-dashboard}__apartbtn {
        margin-top: 10px;
      }
      #{$o-dashboard}__cancelbtn {
        margin-top: 10px;
      }
    }
    &--doctorslot {
      background: #f1f7ff;
      @include media-query.breakpoint(smallg) {
        padding: 20px;
        background: #f1f7ff url(../images/doctor-slot-back.png) no-repeat bottom
          right;
        background-size: contain;
      }
      #{$o-dashboard}__overflowauto {
        height: fit-content;
        padding-bottom: 0px;
        @include media-query.breakpoint(smallg) {
          height: 300px;
        }
      }
    }

    &--doctorcenter {
      background: #fff;
      @include media-query.breakpoint(smallg) {
        padding: 20px;
        background: #fff url(../images/doctor-center-back.png) no-repeat bottom
          right;
        background-size: contain;
      }
      #{$o-dashboard}__rightbody {
        max-width: 780px;
        padding-bottom: 0;
        @include media-query.breakpoint(ipad) {
          margin-top: 50px;
        }
      }
      #{$o-dashboard}__addedtopslot {
        margin-bottom: 0;
        padding: 38px 25px;
      }
      #{$o-dashboard}__addedtopslotimage {
        width: 30%;
        img {
          width: 160px;
        }
      }
    }
    &--doctorcenter1 {
      background: #f1f7ff;
      @include media-query.breakpoint(smallg) {
        padding: 20px;
        background: #f1f7ff url(../images/doctor-center-back.png) no-repeat
          bottom right;
        background-size: contain;
      }
      #{$o-dashboard}__rightbody {
        max-width: 816px;
      }
    }
    &--patienttreatment {
      background: #bdb1f1;
      @include media-query.breakpoint(smallg) {
        padding: 20px;
        background: url(../images/patient-treatmentback.png) no-repeat bottom
          right;
        background-size: cover;
      }
    }
    &--patientappoint {
      background: #f6fafb;
      @include media-query.breakpoint(smallg) {
        padding: 20px;
        background: #efe4fb url(../images/patient-appointback.png) no-repeat
          bottom right;
        background-size: contain;
      }
      #{$o-dashboard}__rightbody {
        padding-bottom: 0;
        max-width: 755px;
      }
      #{$o-dashboard}__overflowauto {
        padding-bottom: 80px;
        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          background: #cdc9fd;
        }

        &::-webkit-scrollbar-thumb {
          background: #a69ff1;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #dfc3ff;
        }
      }
    }

    &--patientsdetails {
      background: #f1f7ff url(../images/patient-details-back.png) no-repeat
        bottom right;
      background-size: 30%;
    }
  }

  &__rightheading {
    padding: 0 0 functions.torem(20px);
    @include media-query.breakpoint(smallg) {
      padding: 0;
    }
  }

  &__rightbody {
    position: relative;
    width: 100%;
    @include media-query.breakpoint(smallg) {
      max-width: 700px;
      padding: functions.torem(10px) 0 functions.torem(20px) 0;
    }
    &--full {
      max-width: 100%;
      padding: 10px 0;
    }
  }

  &__title {
    font-size: 22px;
    font-weight: 600;
    text-transform: fonts.$capitalize;
    margin-bottom: 15px;
    color: #033c81;
    &--modified {
      margin-bottom: 0;
      @include media-query.breakpoint(smallg) {
        margin-bottom: 15px;
      }
    }
  }

  &__profile-name {
    float: right;
    font-size: 20px;
    font-weight: 500;
  }
  &__subtitle {
    font-size: 16px;
    font-weight: fonts.$font-weight--bold;
    margin-bottom: functions.torem(20px);
    color: #033c81;
  }
  &__overflowauto {
    height: fit-content;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: functions.torem(30px);
    @include media-query.breakpoint(smallg) {
      padding-right: functions.torem(10px);
      height: functions.torem(326px);
      margin-top: 20px;
      margin-bottom: 20px;
      padding-bottom: 0;
    }
    @include media-query.breakpoint(extralarge) {
      padding-right: functions.torem(15px);
      height: functions.torem(418px);
    }

    &--appointment {
      @include media-query.breakpoint(extralarge) {
        height: functions.torem(450px);
      }
    }
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #dbe7fe;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #dfc3ff;
    }
  }

  &__app-overflow {
    height: fit-content;
    overflow-y: auto;
    overflow-x: hidden;
    @include media-query.breakpoint(smallg) {
      padding-right: functions.torem(10px);
      height: functions.torem(326px);
      margin-top: 20px;
      margin-bottom: 20px;
      padding-bottom: 0;
    }
    @include media-query.breakpoint(extralarge) {
      padding-right: functions.torem(10px);
      height: functions.torem(300px);
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #dbe7fe;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #dfc3ff;
    }
  }

  &__table-overflow {
    height: fit-content;
    padding-right: functions.torem(5px);
    @include media-query.breakpoint(smallg) {
      height: functions.torem(360px);
      margin-top: 10px;
    }
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #dbe7fe;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #dfc3ff;
    }

    .o-admin__table {
      margin-bottom: 0;
    }
  }

  &__viewcenter {
    position: relative;
    h4 {
      font-size: 16px;
      color: #033c81;
      font-weight: 600;
      margin-bottom: 20px;
    }
  }

  &__viewcenterwrap {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    background-color: #fff;
    border: 1px solid #748ba6;
    border-radius: 12px;
    padding: 12px;
    gap: 15px;
    @include media-query.breakpoint(ipad) {
      padding: 15px;
      justify-content: flex-start;
      align-items: stretch;
    }
  }

  &__imageview {
    width: 100%;
    display: flex;
    @include media-query.breakpoint(ipad) {
      width: 20%;

      height: auto;
    }
    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__leftview {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include media-query.breakpoint(ipad) {
      width: 50%;
      align-items: flex-start;
    }
    .c-label {
      color: #0052b4;
      font-size: 14px;
      margin-bottom: 4px;
    }
    .c-name {
      font-size: 16px;
      color: #0b0b0b;
      margin-bottom: 8px;
      text-transform: uppercase;
    }
    p {
      font-size: 16px;
      &.p-14 {
        font-size: 14px;
      }
    }
    .addsbar {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      margin: 5px 0;
      svg {
        margin-right: 10px;
      }
      p {
        width: 90%;
      }
    }
    .rating {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      margin: 15px 0 0 0;
      font-size: 14px;
      line-height: 16px;
      span {
        display: inline-block;
        margin-left: 5px;
        color: colors.$left-lick-color;
      }
    }
  }
  &__rightview {
    width: 100%;
    margin-top: 20px;
    @include media-query.breakpoint(ipad) {
      width: 25%;
      margin-top: 0;
    }
  }
  &__stillbtn {
    position: absolute;
    background-color: colors.$white;
    padding: 15px 10px;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    @include media-query.breakpoint(ipad) {
      bottom: -32px;
      padding: 15px 10px 30px;
      width: 101%;
    }
  }
  &__dlist {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: functions.torem(14px);
    align-items: center;
    justify-content: center;
  }
  &__ditem {
    position: relative;
    max-width: functions.torem(404px);
    background-color: colors.$white;
    width: 100%;
    height: fit-content;
    border-radius: functions.torem(30px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    @include media-query.breakpoint(ipad) {
      width: 49.2%;
      height: functions.torem(170px);
    }
    @include media-query.breakpoint(extralarge) {
      height: functions.torem(196px);
    }
    &--large {
      @include media-query.breakpoint(ipad) {
        width: 69%;
        max-width: 465px;
      }
      @include media-query.breakpoint(extralarge) {
        height: functions.torem(180px);
      }
      .o-dasboard__ditemsleft {
        img {
          width: 80%;
        }
      }
    }
    &--small {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      @include media-query.breakpoint(ipad) {
        width: 28%;
        max-width: 194px;
      }
      @include media-query.breakpoint(extralarge) {
        height: functions.torem(180px);
      }
      #{$o-dashboard}__ditemlink {
        margin-top: 0;
        margin-bottom: 0;
        svg {
          margin-bottom: 0;
        }
      }
      #{$o-dashboard}__ditemtitle {
        margin-bottom: 0;
        margin-top: 8px;
      }
    }
    &:before {
      position: absolute;
      content: "";
      left: 0;
      top: functions.torem(26px);
      font-size: functions.torem(12px);
      color: colors.$black;
      padding: functions.torem(1.5px) functions.torem(3px);
      text-transform: fonts.$uppercase;
      border-radius: 0 functions.torem(2px) functions.torem(2px) 0;
      min-width: functions.torem(55px);
      text-align: center;
      line-height: normal;
    }
    &--upcoming {
      &:before {
        content: "UPCOMING";
        background-color: #e1c8fd;
      }
    }
    &--past {
      &:before {
        content: "PAST";
        background-color: #cccaff;
      }
    }
  }
  &__ditems {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @include media-query.breakpoint(smallg) {
      align-items: flex-end;
      justify-content: center;
      flex-direction: row;
    }
  }
  &__ditemsleft {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    @include media-query.breakpoint(smallg) {
      width: 50%;
    }
    &--third {
      justify-content: flex-start;
    }
  }
  &__ditemsright {
    width: 100%;
    text-align: center;
    @include media-query.breakpoint(smallg) {
      width: 50%;
      text-align: left;
    }
  }
  &__ditemsleftimg1 {
    position: relative;
    @include media-query.breakpoint(smallg) {
      bottom: -8px;
    }
  }
  &__ditemsleftimg2 {
    position: relative;
    @include media-query.breakpoint(smallg) {
      bottom: -17px;
    }
  }
  &__dflink {
    padding: 12px;
    @include media-query.breakpoint(ipad) {
      padding: functions.torem(15px) functions.torem(15px) 0 0;
    }
    @include media-query.breakpoint(extralarge) {
      padding: functions.torem(35px) functions.torem(35px) 0 0;
    }
  }
  &__dslink {
    text-align: center;
    padding: 12px;
    @include media-query.breakpoint(ipad) {
      padding: functions.torem(15px);
    }
    @include media-query.breakpoint(extralarge) {
      padding: functions.torem(20px);
    }
  }
  &__ditemtitle {
    font-family: Poppins, sans-serif;
    font-size: functions.torem(18px);
    color: colors.$left-lick-color;
    margin-bottom: functions.torem(5px);
    margin-top: functions.torem(15px);
    font-weight: fonts.$font-weight--bolder;
    line-height: normal;
    @include media-query.breakpoint(smallg) {
      margin-top: 0;
    }
  }
  &__ditemcontent {
    @include fonts.font-size--normal;
    color: colors.$left-lick-color;
    margin: 0;
  }
  &__ditemlink {
    margin-top: functions.torem(2px);
    color: #b670ea;
    font-size: functions.torem(16px);
    text-transform: fonts.$uppercase;
    &:hover {
      path {
        stroke: colors.$body-color;
      }
    }
  }
  &__pbox {
    margin-bottom: functions.torem(32px);
  }
  &__hamburg {
    display: block;
    background-color: colors.$white;
    width: functions.torem(40px);
    height: functions.torem(40px);
    border-radius: functions.torem(5px);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 functions.torem(10px);
    padding: functions.torem(10px);
    top: 0;
    right: functions.torem(15px);
    @include media-query.breakpoint(smallg) {
      display: none;
    }
  }

  &__tabheader {
    margin-bottom: 3px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow: auto;
    padding-bottom: 12px;
    @include media-query.breakpoint(smallg) {
      display: block;
      justify-content: center;
      flex-wrap: wrap;
      padding-bottom: 0;
      overflow: hidden;
    }
    &--notbtn {
      border-bottom: 1px solid #83a3e3;
      #{$o-dashboard}__tabtn {
        border-radius: 0;
        border: 0;
        margin: 0;
        color: #427fc9;
        &.active {
          color: #033c81;
          font-weight: fonts.$font-weight--bold;
          background-color: transparent;
          &:after {
            position: absolute;
            content: "";
            width: 100%;
            height: 2px;
            background-color: #033c81;
            bottom: -1px;
            left: 0;
          }
        }
      }
    }
  }
  &__tabtn {
    position: relative;
    font-size: functions.torem(14px);
    color: #99bbe4;
    margin: 0 functions.torem(5px);
    padding: functions.torem(12px);
    border-radius: 60px;
    line-height: normal;
    font-weight: fonts.$font-weight--bold;
    border: 1px solid #99bbe4;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    @include media-query.breakpoint(smallg) {
      font-size: functions.torem(15px);
      padding: functions.torem(10px) functions.torem(13px);
    }
    &.active {
      color: #033c81;
      background-color: #c0dcff;
      border-color: #c0dcff;
    }
  }

  &__aplist {
    position: relative;
    border-radius: functions.torem(12px);
    margin-bottom: functions.torem(8px);
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    background-color: #daebff;
    border: 1px solid #dbe7fe;
    &:hover {
      background-color: #c0dcff;
      border: 1px solid #dbe7fe;
    }
    &--upcoming {
      background-color: #daebff;
      &:hover {
        background-color: #c0dcff;
      }
    }
    &--past {
      background-color: #d2d2d2;
    }
    &--canceled {
      background-color: #ffd1d1;
    }
    @include media-query.breakpoint(ipad) {
      flex-wrap: nowrap;
      height: 275px;
    }
    &:before {
      position: absolute;
      content: "";
      right: functions.torem(1px);
      top: functions.torem(26px);
      font-size: functions.torem(12px);
      color: colors.$black;
      padding: functions.torem(1.5px) functions.torem(3px);
      min-width: functions.torem(86px);
      text-transform: fonts.$uppercase;
      border-radius: functions.torem(2px) 0 0 functions.torem(2px);
      text-align: center;
      line-height: normal;
      display: none;
    }
    &--coloum {
      flex-direction: column;
    }
    &--pending {
      &:before {
        display: block;
        content: "pending";
        background-color: #e1c8fd;
      }
    }
    &--slote {
      height: auto;
      background-color: colors.$white;
      #{$o-dashboard}__apart {
        border: 0;
        padding: 17px;
        label {
          font-size: 14px;
          text-align: left;
        }
        p {
          font-weight: 500;
        }
      }
    }
  }

  &__apart {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: functions.torem(20px);
    text-align: center;
    @include media-query.breakpoint(smallg) {
      width: auto;
      border-right: 1px solid #88bdff;
      padding: functions.torem(20px) functions.torem(20px);
      text-align: left;
    }
    @include media-query.breakpoint(ipad) {
      height: 100%;
    }
    &--top-title {
      margin-bottom: 10px;
      h3 {
        margin-bottom: 10px;
      }
      h6 {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 5px;
        strong {
          font-weight: fonts.$font-weight--bold;
          color: #0e3e79;
        }
      }
      h6.clinic-name {
        font-size: 16px;
      }
      h6.clinic-address {
        font-size: 14px;
      }
      h6.clinic-number {
        font-size: 14px;
      }
    }
    label {
      font-size: functions.torem(14px);
      color: #0e3e79;
      line-height: normal;
      margin: 0 0 4px;
      text-align: center;
    }
    p {
      font-size: functions.torem(16px);
      color: colors.$left-lick-color;
      line-height: normal;
      strong {
        font-weight: fonts.$font-weight--bold;
      }
    }
    &--user {
      @include media-query.breakpoint(ipad) {
        width: 30%;
        max-width: functions.torem(165px);
        justify-content: flex-start;
        align-items: center;
      }
    }
    &--date {
      border: none;
      @include media-query.breakpoint(ipad) {
        width: 60%;
        max-width: functions.torem(340px);
        padding-right: 5px;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
    &--bottom {
      border: none;
      gap: 10px;
      @include media-query.breakpoint(ipad) {
        margin-top: 0;
        padding-left: 5px;
      }
      @include media-query.breakpoint(ipad) {
        justify-content: flex-end;
      }
    }
    &--slot {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-top: 1px solid #afcafe;
      margin: functions.torem(15px) 0;
      @include media-query.breakpoint(ipad) {
        margin: 0;
        width: 42%;
        align-items: flex-start;
        justify-content: flex-start;
        border-top: none;
        border-left: 1px solid #afcafe;
      }
    }
    &--button {
      display: flex;
      gap: functions.torem(8px);
      justify-content: center;
      flex-direction: row;
      width: 100%;
      border: none;
      @include media-query.breakpoint(ipad) {
        justify-content: flex-start;
        width: auto;
      }
      .o-dasboard__apartbtn {
        min-width: auto;
        max-width: functions.torem(119px);
        width: 49%;

        &--delete {
          border: 0.0625rem solid #7d7d7d;
          color: #7d7d7d;
          &:hover {
            background-color: #ce0000;
            color: var(--white);
          }
        }
      }
    }
  }
  &__apartuserimage {
    width: 75px;
    height: 75px;
    border-radius: 100%;
    margin: 0 auto 14px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__apartbtn {
    min-width: 100%;
    width: fit-content;
    height: functions.torem(48px);
    border: functions.torem(1px) solid #7500ce;
    color: #7500ce;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: fonts.$uppercase;
    padding: 0 functions.torem(10px);
    font-size: functions.torem(14px);
    gap: 20px;
    @include media-query.breakpoint(smallg) {
      min-width: functions.torem(170px);
      // margin-left: functions.torem(10px);
    }
    &:hover {
      background-color: #7500ce;
      color: colors.$white;
      path {
        stroke: colors.$white;
      }
    }

    &--solid-small {
      background-color: #7500ce;
      color: #fff !important;
      height: 32px;
      padding: 0px 15px;
      border-radius: 3px;
      &:hover {
        background-color: #3f006f !important;
      }
    }

    &--upload-btn {
      border: #5E04A1 1px solid;
      background-color: transparent;
      color: #5E04A1 !important;
      height: 32px;
      font-size: 14px;
      line-height: 14px;
      padding: 20px 10px;
      border-radius: 8px;
      &:hover {
        background-color: #3f006f !important;
        color: #fff !important;
      }
    }

    &--appbtn {
      margin-left: 0;
      height: functions.torem(36px);
    }
    &:disabled {
      opacity: 0.2;
    }
    &--pabsolute {
      margin-top: 20px;
      @include media-query.breakpoint(smallg) {
        position: absolute;
        right: 20px;
        bottom: 20px;
      }
    }
    &--delete-solid {
      background-color: #ce0000;
      border-color: #ce0000;
      color: colors.$white;
      &:hover {
        background-color: #8d0000;
        color: colors.$white;
      }
    }
    &--cancel-solid {
      border-color: #7b7b7b;
      color: #7b7b7b;
      &:hover {
        background-color: #7b7b7b;
        color: colors.$white;
      }
    }
  }
  &__cancelbtn {
    min-width: 100%;
    width: fit-content;
    height: functions.torem(48px);
    border: functions.torem(1px) solid #7d7d7d;
    color: #7d7d7d;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: fonts.$uppercase;
    padding: 0 functions.torem(10px);
    font-size: functions.torem(14px);
    @include media-query.breakpoint(smallg) {
      min-width: functions.torem(170px);
    }
    &:hover {
      background-color: #ce0000;
      color: colors.$white;
      path {
        stroke: colors.$white;
      }
    }
    &:disabled {
      opacity: 0.2;
    }
  }
  &__apartbtnback {
    color: #7500ce;
    border-radius: 8px;
    text-transform: fonts.$uppercase;
    font-size: functions.torem(16px);
    &:hover {
      color: colors.$black;
      path {
        stroke: colors.$black;
      }
    }
  }
  &__apartbtnsvg {
    margin-right: functions.torem(8px);
  }
  &__apdetailswrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    flex-wrap: wrap;
    label {
      font-size: 14px;
      color: #0052b4;
      line-height: normal;
    }
    p {
      font-size: 15px;
      color: #0b0b0b;
      line-height: normal;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
      b {
        font-weight: 500;
      }
      &.p-14 {
        font-size: 14px;
      }
    }
  }
  &__apdetailshalf {
    width: 100%;
    height: fit-content;
    border-radius: 22px;
    padding: 22px;
    @include media-query.breakpoint(smallg) {
      width: 49%;
      height: 120px;
    }
  }
  &__apdetailsfull {
    position: relative;
    width: 100%;
    height: fit-content;
    background: #c0dcff;
    border-radius: 22px;
    padding: 20px;
    @include media-query.breakpoint(smallg) {
      height: 305px;
      background: #c0dcff url(../images/fulldetails-back.png) no-repeat left
        bottom;
      background-size: cover;
    }
  }
  &__aphalfgroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    svg {
      margin-right: 5px;
      margin-top: 9px;
      width: 15px;
    }
  }
  &__aphalfgrouptext {
    width: 88%;
  }

  &__upload-doc-wrapper {
    border: 1px solid #9FC0F2;
    border-radius: 20px;
    padding: 15px 20px 20px 20px;
  }

  &__profileview {
    position: relative;
    max-width: 625px;
    margin-top: 20px;
    padding: 0 10px;
    height: 100%;
    #{$o-dashboard}__overflowauto {
      height: 350px;
    }
  }
  &__profileimage {
    position: relative;
    width: functions.torem(100px);
    height: functions.torem(100px);
    border-radius: 50%;
    display: block;
    margin-bottom: 15px;
    @include media-query.breakpoint(smallg) {
      margin-bottom: 0;
    }

    &--admin {
      width: functions.torem(80px);
      height: functions.torem(80px);
      @include media-query.breakpoint(smallg) {
        width: functions.torem(140px);
        height: functions.torem(140px);
      }
    }
  }
  &__userimage {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
  &__tableuserimage {
    width: 45px !important;
    height: auto;
    border-radius: 50%;
    object-fit: cover;
  }
  &__uploadinput {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: z-index.$on-top;
    cursor: pointer;
  }

  &__uploadimage {
    position: absolute;
    bottom: 0;
    right: 0;
    width: functions.torem(27px);
    height: functions.torem(27px);
  }
  .form-group {
    margin-bottom: functions.torem(30px);
    label {
      font-size: functions.torem(14px);
      line-height: 14px;
      color: #0052b4;
      margin-bottom: functions.torem(10px);
    }
    .form-control {
      font-size: functions.torem(15px);
      border-radius: functions.torem(8px);
      border: 1px solid #748ba6;
      height: functions.torem(50px);
      margin: 0;
      padding: 6px 12px;
      &--textarea {
        height: 108px;
        &:disabled {
          background: transparent;
          height: 108px;
        }
      }
      &--textareas {
        height: 108px;
        &:disabled {
          background: transparent;
          height: 200px !important;
          white-space: inherit;
        }
      }
      &:disabled {
        background: transparent;
        height: fit-content;
        border: 0px;
        padding: 0;
      }
    }
  }

  &__centeredit {
    margin-top: 20px;
    .form-group {
      margin-bottom: 15px;
    }
    #{$o-dashboard}__apartbtn {
      margin-top: 15px;
    }
  }

  &__addedtopslot {
    background: rgb(166, 155, 239);
    background: linear-gradient(
      90deg,
      rgba(166, 155, 239, 1) 0%,
      rgba(241, 184, 194, 1) 32%,
      rgba(241, 229, 255, 1) 100%
    );
    border-radius: functions.torem(10px);
    padding: functions.torem(15px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    margin-bottom: 20px;
    @include media-query.breakpoint(ipad) {
      padding: functions.torem(20px) functions.torem(35px);
      margin-top: functions.torem(5px);
      margin-bottom: functions.torem(20px);
      justify-content: space-between;
      flex-direction: row;
      margin-bottom: 0;
    }
    &--doctor-screen {
      margin-top: 32px;
    }
  }

  &__emptyslot {
    background-color: colors.$white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 12px;
    border: 1px solid rgba(219, 231, 254, 0.57);
    box-shadow: 0 0 9px 0 rgba(219, 231, 254, 0.55);
    padding: 11px 30px;
    svg {
      margin-right: 40px;
    }
    p {
      font-size: 14px;
      color: colors.$black;
    }
  }

  &__addnewslot {
    border-radius: 10px;
    background: #a69bef;
    background: linear-gradient(90deg, #a69bef 0, #f1b8c2 32%, #f1e5ff 100%);
    padding: 5px;
    margin-top: 50px;
    &--exclusive {
      @include media-query.breakpoint(smallg) {
        height: 450px;
      }
      #{$o-dashboard}__addslot {
        @include media-query.breakpoint(smallg) {
          height: 360px;
        }
      }
    }
  }
  &__addnewslotop {
    display: flex;
    padding: 45px 30px 17px;
    .position-relative {
      width: 95px;
      margin-right: 24px;
      img {
        position: absolute;
        bottom: -17px;
      }
    }
    p {
      font-weight: 600;
      font-size: 18px;
    }
  }
  p {
    font-size: functions.torem(15px);
    color: colors.$left-lick-color;
    margin: 0;
    .strong {
      font-size: functions.torem(16px);
      font-weight: fonts.$font-weight--bold;
    }
    &.p-14 {
      font-size: 14px;
    }
    &.blue {
      color: #0052b4;
    }
  }
  &__addedtopslotimage {
    width: 100%;
    text-align: center;
    display: none;
    @include media-query.breakpoint(smallg) {
      display: block;
    }
    @include media-query.breakpoint(ipad) {
      width: 25%;
      text-align: left;
    }
    img {
      @include media-query.breakpoint(ipad) {
        position: absolute;
        left: 20px;
        bottom: 0;
      }
    }
  }
  &__addedtopslotcontent {
    width: 100%;
    text-align: center;
    margin: functions.torem(15px) 0;
    @include media-query.breakpoint(ipad) {
      width: 65%;
      text-align: left;
      margin: 0;
    }
  }
  &__addedtopbtn {
    width: 100%;
    @include media-query.breakpoint(smallg) {
      width: functions.torem(164px);
    }
  }
  &__addedtopslotbtn {
    width: 100%;
    height: functions.torem(50px);
    border-radius: functions.torem(8px);
    display: flex;
    align-items: center;
    justify-content: center;
    border: functions.torem(1px) solid #b670ea;
    background-color: #b670ea;
    color: #fff;
    font-size: functions.torem(16px);
    &:hover {
      color: #b670ea;
      background-color: #fff;
    }
  }
  &__daylist {
    margin-bottom: functions.torem(10px);
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__dayname {
    font-size: functions.torem(20px);
    color: #033c81;
    margin-bottom: functions.torem(8px);
  }
  &__apartone {
    padding: 0 functions.torem(10px);
    @include media-query.breakpoint(ipad) {
      padding: 0 0 0 functions.torem(40px);
    }
  }
  &__addslot {
    background-color: colors.$white;
    border-radius: functions.torem(12px);
    padding: functions.torem(20px);
  }
  &__close {
    position: absolute;
    right: functions.torem(30px);
    top: functions.torem(15px);
  }
  &__extendedadded {
    background-color: #f9f3ff;
    border: 1px solid #f1e5ff;
    border-radius: functions.torem(4px);
    padding: functions.torem(20px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: functions.torem(10px);
    @include media-query.breakpoint(ipad) {
      justify-content: space-between;
      flex-direction: row;
    }
  }
  &__extendedleft {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: functions.torem(15px);
    @include media-query.breakpoint(smallg) {
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: row;
      margin-bottom: 0;
    }
    svg {
      margin: 0 0 functions.torem(10px);
      @include media-query.breakpoint(smallg) {
        margin: functions.torem(6px) functions.torem(10px) 0 0;
      }
    }
    span {
      font-size: functions.torem(14px);
      color: colors.$left-lick-color;
      line-height: normal;
      text-align: center;
      @include media-query.breakpoint(smallg) {
        text-align: left;
      }
    }
    p {
      font-size: functions.torem(16px);
      color: colors.$left-lick-color;
      line-height: normal;
      text-align: center;
      margin: 0;
      @include media-query.breakpoint(smallg) {
        text-align: left;
      }
    }
  }
  &__priceview {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;
    @include media-query.breakpoint(ipad) {
      gap: 0;
    }
  }
  &__planbox {
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 100%;
    padding: functions.torem(59px) functions.torem(30px) functions.torem(24px);
    background-color: colors.$white;
    border: functions.torem(1px) solid colors.$border-color1;
    border-radius: 12px;
    box-shadow: 0 functions.torem(4px) functions.torem(4px) 0
      rgba(194, 213, 249, 0.5);
    transition: all 0.5s ease-out;
    margin: 7px 0;
    @include media-query.breakpoint(smallg) {
      max-width: functions.torem(232px);
      margin: 0 functions.torem(7px);
      padding: functions.torem(48px) functions.torem(10px) functions.torem(10px);
    }
    @include media-query.breakpoint(medium) {
      max-width: functions.torem(273px);
      padding: functions.torem(52px) functions.torem(20px) functions.torem(24px);
    }
    @include media-query.breakpoint(elarge) {
      max-width: functions.torem(307px);
      padding: functions.torem(59px) functions.torem(30px) functions.torem(24px);
    }

    &--popular {
      &::before {
        content: "POPULAR";
        position: absolute;
        top: functions.torem(22px);
        left: 0;
        display: flex;
        width: functions.torem(78px);
        height: functions.torem(23px);
        justify-content: center;
        align-items: center;
        color: colors.$black;
        font-size: functions.torem(12px);
        text-transform: fonts.$uppercase;
        background-color: rgba(195, 119, 252, 0.19);
      }
    }

    &:hover {
      box-shadow: 0 functions.torem(10px) functions.torem(10px) 0
        rgba(194, 213, 249, 0.7);
    }
  }
  &__planboxtop {
    width: 100%;
    padding-bottom: functions.torem(12px);
    text-align: center;
    border-bottom: functions.torem(1px) solid colors.$border-color3;
  }

  &__planame {
    font-size: 16px;
    margin-bottom: functions.torem(8px);
    text-align: center;
  }

  &__plancost {
    font-size: 48px !important;
    line-height: normal !important;
    margin-bottom: functions.torem(2px);
    color: colors.$black;
    font-weight: 600;
  }

  &__plantip {
    font-size: 14px;
    color: colors.$black;
  }

  &__planlist {
    padding-top: functions.torem(14px);
  }

  &__planitem {
    position: relative;
    font-size: 14px;
    line-height: 23px;
    margin-bottom: functions.torem(16px);
    padding-left: functions.torem(30px);
    color: colors.$black;
    background: url(../images/icons/li-before.svg) no-repeat;
    background-position: left center;
    background-size: 20px;
  }

  &__planbtn {
    font-size: 14px;
    display: flex;
    // max-width: functions.torem(148px);
    width: 100%;
    height: functions.torem(40px);
    justify-content: center;
    align-items: center;
    margin: functions.torem(30px) auto 0;
    font-weight: 600;
    border-radius: 8px;
    @include media-query.breakpoint(medium) {
      width: fit-content;
      padding: 5px 18px;
    }
  }

  &__plandetails {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 10px;
    flex-direction: column-reverse;
    @include media-query.breakpoint(medium) {
      flex-direction: row;
    }
  }

  &__planleft {
    width: 100%;
    @include media-query.breakpoint(medium) {
      width: 70%;
    }

    #{$o-dashboard}__overflowauto {
      padding-right: 0;
      height: 300px;
      h3 {
        font-size: 18px;
        font-weight: 700;
        color: #0b0b0b;
        margin-bottom: 4px;
      }
    }
  }

  &__currentplan {
    width: 100%;
    border-radius: 10px;
    padding: 20px 20px 0;
    background: rgb(166, 155, 239);
    background: linear-gradient(
      90deg,
      rgba(166, 155, 239, 1) 0%,
      rgba(241, 184, 194, 1) 32%,
      rgba(241, 229, 255, 1) 100%
    );
    display: flex;
    margin-bottom: 8px;
  }
  &__planimage {
    width: 25%;
    display: flex;
  }
  &__plantext {
    width: 75%;
    padding-left: 30px;
    .c-plan {
      max-width: 243px;
      border-bottom: 1px solid #a695ec;
      h4 {
        font-size: 18px;
        line-height: normal;
        color: #0b0b0b;
        margin: 0 0 5px;
        font-weight: 700;
      }
      p {
        font-size: 14px;
        color: #0b0b0b;
        padding-bottom: 12px;
        margin-bottom: 15px;
      }
    }
    .planpc {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding-top: 8px;
      padding-bottom: 15px;
      flex-direction: column;
      @include media-query.breakpoint(smallg) {
        flex-direction: row;
        align-items: center;
      }
      h5 {
        margin: 0;
        font-size: 16px;
        font-weight: 600;
        color: #0b0b0b;
      }
      span {
        color: #033c81;
        font-size: 30px;
        font-weight: 600;
      }
    }
  }
  &__planusages {
    background-color: #f1e5ff;
    border-radius: 10px;
    padding: 20px;
    .planuse {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 8px;
      h4 {
        font-size: 18px;
        color: #0b0b0b;
        font-weight: 700;
        margin: 0 12px 0 0;
      }
      p {
        font-size: 12px;
        color: #000;
      }
    }
  }
  &__planuseblock {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 10px;
    flex-wrap: wrap;
    gap: 15px;
    @include media-query.breakpoint(smallg) {
      flex-wrap: nowrap;
      gap: 0;
    }
  }
  &__blockset {
    width: 100%;
    border-radius: 10px;
    background-color: #fcf9ff;
    padding: 12px;
    min-height: 140px;
    @include media-query.breakpoint(smallg) {
      width: 49.5%;
    }
    p {
      font-size: 12px;
      &.p-14 {
        font-size: 14px;
      }
    }
    h4 {
      margin: 0 0 15px;
      font-weight: 600;
      font-size: 15.5px;
      line-height: normal;
    }
  }
  &__planright {
    width: 100%;
    @include media-query.breakpoint(medium) {
      width: 28%;
    }
  }
  &__upgradeplan {
    width: 100%;
    border-radius: 20px;
    padding: 25px;
    display: flex;
    background: #a69ff1;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    flex-direction: column;
    @include media-query.breakpoint(medium) {
      height: 442px;
      padding: 34px;
      justify-content: flex-end;
      align-items: flex-start;
      background: url(../images/upgrade-plan.png) no-repeat top center;
      background-size: cover;
      margin-bottom: 0;
    }
    h4 {
      font-size: 19px;
      font-weight: 600;
      color: #fff;
      margin-bottom: 20px;
    }
    .btn {
      border: 1px solid #7500ce;
      border-radius: 8px;
      width: 100%;
      background-color: #fff;
      color: #7500ce;
      font-size: 16px;
      padding: 10px;
      &:hover {
        background-color: #7500ce;
        color: #fff;
      }
    }
  }
  &__treatment {
    position: relative;
    max-width: 530px;
    margin: auto;
    .splide__arrow {
      border: 1px solid #fff;
      background: transparent;
      svg {
        fill: #fff;
      }
      &--prev {
        left: -50px;
      }
      &--next {
        right: -35px;
      }
    }
  }
  &__treatmentbox {
    max-width: 234px;
    min-height: 379px;
    border-radius: 30px;
    background-color: #f4e4ff;
    display: flex;
    flex-direction: column;
    padding: 12px;
    i {
      width: 210px;
      height: 210px;
      border-radius: 30px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    h4 {
      font-size: 18px;
      font-weight: 600;
      color: #033c81;
      margin: 15px 0;
      padding: 0;
      text-align: center;
    }
    svg {
      display: block;
      margin: auto;
    }
  }
  &__treatmenttitle {
    font-size: 18px;
    color: #033c81;
    margin-top: 25px;
    margin-bottom: 15px;
    font-weight: 600;
  }
  &__treatmentbody {
    p {
      font-size: 16px;
      line-height: 29px;
      strong {
        font-weight: 600;
      }
    }
  }
  &__booktypelist {
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
  }
  &__booktypeitem {
    max-width: 268px;
    width: 33%;
    background-color: #AEB6FF;
    border-radius: 10px;
    padding: 12px;
    margin-bottom: 10px;
    i {
      display: block;
      width: 100%;
      height: 153px;
      margin-bottom: 12px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin-bottom: 13px;
      }
    }
    h3 {
      font-size: 18px;
      color: #033c81;
      font-weight: 600;
      margin-bottom: 15px;
      line-height: normal;
      min-height: 55px;
    }
  }
  &__typeitembtnarea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .nbrder {
      width: 49%;
      height: 37px;
      border-radius: 8px;
      color: #7500ce;
      font-size: 16px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid transparent;
      &:hover {
        border-color: #7500ce;
      }
    }
    .brder {
      width: 49%;
      height: 37px;
      border-radius: 8px;
      color: #fff;
      background-color: #b670ea;
      font-size: 16px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #b670ea;
      &:hover {
        background-color: transparent;
        color: #7500ce;
        border-color: #7500ce;
      }
    }
  }
  &__doctorlist {
    background-color: #e9e9ff;
    padding: 20px;
    border-radius: 6px;
    border: 1px solid #e4c1ff;
    margin-bottom: 8px;
    i {
      width: 75px;
      height: 75px;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .blue {
      margin-bottom: 15px;
    }
    .link-p {
      font-size: 16px;
      text-transform: uppercase;
      color: #7500ce;
    }
    &--block {
      border: 0px;
      padding: 8px;
      height: 400px;
      i {
        margin: 25px 0 28px;
      }
      .row {
        height: 100%;
      }
      .framearea {
        border-radius: 4px;
        background-color: #fff;
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }
  &__slotdoctor {
    position: relative;
    height: 100%;
    
    #slots-container {
        height: 100%;
       
        #slots-list {
            width: 100%;
            height: 400px;
            overflow: auto;
        }
    }
    .date {
      font-size: 18px;
      color: #0b0b0b;
      font-weight: 600;
      margin-bottom: 10px;
    }
  }
}

#calendar {
    padding: 5px;
    height: 100%;
}

// TAB VIEW
.o-tab {
  $o-tab: &;

  &__button {
    display: inline-block;
    background: transparent;
  }

  &__content {
    display: none;
  }

  // .active {
  // 	background: transparent;
  // }
}

// POPUP
.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(32, 58, 107, 0.35);
  align-items: center;
  justify-content: center;
  z-index: 9;
  left: 0;
  top: 0;
  display: none;
  &--view {
    .popupclose {
      position: absolute;
      right: 15px;
      top: 15px;
    }
    svg {
      margin-bottom: 20px;
    }
    h3 {
      color: #033c81;
      margin: 0 !important;
    }
    h6 {
      color: #033c81;
      margin-bottom: 20px;
    }
    p {
      font-size: 16px;
      margin-bottom: 30px;
    }
    .popup__bg {
      padding: functions.torem(45px) functions.torem(35px);
    }
  }
  &__bg {
    position: relative;
    text-align: center;
    max-width: functions.torem(563px);
    width: 99%;
    padding: 0 functions.torem(50px) functions.torem(50px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: colors.$white;
    border-radius: 10px;
    &--allpadding {
      padding: functions.torem(50px);
    }
  }

  &__chatclose {
    color: #000;
    transform: rotate(45deg);
    position: absolute;
    right: 9px;
    top: 2px;
    font-size: 30px;
  }
  &__chats {
    position: relative;
    margin-top: 30px;
    padding: 20px;
  }
  &__chatlist {
    position: relative;
  }
  &__chatitem {
    max-width: 80%;
    width: fit-content;
    position: relative;
    background: #f3e3ff;
    margin-bottom: 25px;
    display: flex;
    border-radius: 10px;
    padding: 15px;
    &:after {
      position: absolute;
      content: "";
      left: 0;
      bottom: -11px;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 20px 20px 0 0;
      border-color: #f3e3ff transparent transparent transparent;
      transform: rotate(0deg);
    }
    &--relay {
      background: #fff;
      float: right;
      &:after {
        position: absolute;
        content: "";
        left: 96%;
        bottom: -11px;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 0 20px 20px 0;
        border-color: transparent #fff transparent transparent;
        transform: rotate(0deg);
      }
    }
    img {
      margin-right: 10px;
    }
    p {
      width: 90%;
      font-size: 16px !important;
      margin: 0 !important;
    }
    .date {
      position: absolute;
      bottom: 10px;
      right: 10px;
      font-size: 14px;
    }
  }
  &__btnchat {
    position: absolute;
    left: 0;
    bottom: -40px;
    right: 0;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }

  img {
    margin-bottom: 20px;
  }
  h3 {
    font-size: functions.torem(30px);
    font-weight: fonts.$font-weight--bold;
    line-height: normal;
    margin-bottom: functions.torem(15px);
  }
  h6 {
    font-size: functions.torem(20px);
    font-weight: fonts.$font-weight--bold;
    margin-bottom: functions.torem(28px);
  }
  p {
    font-size: functions.torem(16px);
    margin-bottom: functions.torem(40px);
  }
  &__button-wrapper {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @include media-query.breakpoint(smallg) {
      flex-direction: row;
    }
  }
  &__slotdetails {
    background-color: #faf6ff;
    border-radius: 4px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    margin-bottom: 30px;
    flex-direction: column;
    p {
      margin-bottom: 25px;
    }
  }
  &__detailstitle {
    font-weight: fonts.$font-weight--bold;
    margin-bottom: 15px;
    color: colors.$body-color;
  }
  &__detailsarea {
    max-width: 360px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    label {
      font-size: 14px;
      color: #5d93d4;
    }
    p {
      font-size: 16px;
      color: colors.$black;
      font-weight: fonts.$font-weight--bold;
      margin: 0;
    }
  }
  &__arealeft {
    width: 100%;
    border-right: 1px solid #afcafe;
    @include media-query.breakpoint(smallg) {
      width: 40%;
    }
  }
  &__arearight {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    @include media-query.breakpoint(smallg) {
      width: 60%;
    }
  }
  &__btnarea {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .o-dasboard__apartbtn {
      margin: 0 7px;
    }
  }
}

.float-medcore {
  position: absolute;
  bottom: 0;
  left: functions.torem(10px);
  z-index: 2;
}

.o-accordion {
  $o-accordion: &;

  &__item {
    position: relative;
    z-index: z-index.$on-top;
    margin-bottom: functions.torem(10px);
    background-color: colors.$white;
    border-radius: 4px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  }

  &__icon {
    position: absolute;
    right: functions.torem(10px);
    width: functions.torem(30px);
    stroke: colors.$black;
  }

  &__button {
    display: flex;
    margin-bottom: 0;
    padding: functions.torem(10px) functions.torem(15px);
    color: colors.$black;
    text-align: left;
    transition: 0.4s;
    cursor: pointer;
    outline: none;
    @include media-query.breakpoint(smallg) {
      padding: functions.torem(20px) functions.torem(25px);
    }

    .font-size--accordion-title {
      width: 90%;
      font-weight: fonts.$font-weight--bold;
      color: #033c81;
      font-size: functions.torem(18px);
    }
  }

  &__inner-content {
    display: none;
  }

  &__inner-header {
    cursor: pointer;
  }

  &__content {
    max-height: 0;
    padding: 0 functions.torem(15px);
    color: colors.$black;
    @include fonts.font-size--accordion-content;
    transition: max-height 0.2s ease-out;
    overflow: hidden;
    @include media-query.breakpoint(smallg) {
      padding: 0 functions.torem(25px);
    }

    &.isActive {
      display: block;
    }
  }

  &__content-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    color: colors.$black;
    @include media-query.breakpoint(small) {
      align-items: center;
      flex-wrap: nowrap;
    }
  }

  &__paragraph {
    max-width: 77%;
    font-size: functions.torem(18px);
    @include media-query.breakpoint(large) {
      max-width: functions.torem(650px);
    }

    span {
      display: block;
      font-size: 22px;
    }
  }

  &__content-spacing {
    // padding-top: functions.torem(30px);
    padding-bottom: functions.torem(40px);
  }
}
.pb-40 {
  padding-bottom: 125px;
}

// RADIO BUTTON
/* label container */
.radio-button-container {
  display: block;
  position: relative;
  padding: 20px 10px 20px 45px;
  margin-bottom: 12px !important;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  h6 {
    font-size: 18px;
    color: #0b0b0b;
    font-weight: 600;
    line-height: normal;
    margin: 0;
  }
}

/* Hide the browser's default radio button */
.radio-button-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 25px;
  left: 10px;
  height: 16px;
  width: 16px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 50%;
  border: 1px solid #0b0b0b;
  transition: all 0.3s;
}

/* On mouse-over, add a grey background color */
.radio-button-container:hover input ~ .checkmark {
  border-color: rgba(0, 0, 0, 0.5);
}

/* When the radio button is checked */
.radio-button-container input:checked ~ .checkmark {
  background-color: rgba(0, 0, 0, 0);
  border-color: #b670ea;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-button-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-button-container .checkmark:after {
  top: 2px;
  left: 2px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #b670ea;
}

.chatbot-codes-modal-body {
  padding-bottom: 5px !important;
}

.chatbot-codes {
  text-wrap: wrap;
  font-weight: 500;
  color: #fff;
  font-size: 14px;
}

.chatbot-codes-area {
  background-color: #212121;
  padding: 10px;
  border: #fff 1px solid;
  border-radius: 10px;
  line-height: 24px;
}

.chatbot-modal-footer {
  .modal-footer-btn-active {
    display: inline-flex;
    font-size: 18px;
    background-color: #c67efc;
    color: #fff;
    border-radius: 68px;
    padding: 10px 40px;
    border: 1px solid #c67efc;
    &:hover {
      background-color: #b250fd;
      color: #fff;
    }
  }
}

.doctor-app-image {
    width: 75px;
    height: 75px;
    border-radius: 100%;
    overflow: hidden;
}

.slot-deatils {
    background-color: #b670ea;
    padding: 10px;
    
}
.slot-deatils-date-time 
{
    display: flex;
    justify-content: space-between;
    p {
      color: #fff;
      font-weight: 600;
    }
}
.slot-deatils-doctor
{
    padding: 15px 15px 5px 15px;
    p{
      font-size: 15px;
      line-height: 24px;
      margin-bottom: 10px;
      color: #3e3e3e;
      &:last-child {
        margin-bottom: 0;
      }
    }
    i {
      margin-right: 10px;
      font-size: 18px;
      color: #0052b4;
    }
}
.slot-deatils-clinic
{
    padding: 15px 15px;
    h3 {
      margin-bottom: 10px;
      font-weight: 600;
      font-size: 16px;
    }
    p{
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 10px;
      color: #3e3e3e;
      &:last-child {
        margin-bottom: 0;
      }
    }
    i {
      margin-right: 10px;
      font-size: 18px;
      color: #0052b4;
    }
}

.slot-deatils-card 
{
    border-radius: 5px;
    background-color: #e9e9ff;
    overflow: hidden;
    margin-bottom: 5px;
    &:last-child {
        margin-bottom: 0;
    }
}

.book-slot-btn 
{
    width: 100%;
}

.upload-med-input 
{
  input {
    margin: auto;
    padding: 10px;
    border: 1px solid #C1C1C1;
    background-color: #fff;
    transition: border-color .25s ease-in-out;
    &::file-selector-button{
      padding: 10px 20px;
      border-width: 0;
      border-radius: 10px;
      background-color: #7500CE;
      color: #fff;
      transition: all .25s ease-in-out;
      cursor: pointer;
      margin-right: 1em;
    }
    &:hover {
      border-color: #888;
      &::file-selector-button{
        background-color: #440077;
      }
    }
  }
}

.upload-med-context 
{
  color: #8D8D8D;
  font-size: 14px;
  margin-top: 15px;
  margin-bottom: 20px !important;
}