@use "../abstracts/media-query";
@use "../abstracts/functions";
@use "../abstracts/colors";
@use "../abstracts/fonts";
@use "../abstracts/z-index";
.o-admin{
    $o-admin : &;

    &.o-login{
        height: 100vh;
        overflow: hidden;
    }

    .o-header__logo{
        display: block;
    }
    // .o-login__rightboxtittle{
    //     padding: 0 0 0 18px;
    // }

    &__patient-form {
        
    }
    &__resetpass{
        max-width: 518px;
        padding: 40px 20px;
        #{$o-admin}__formgroup{
            flex-direction: column;
            label{
                font-size: 14px;
                margin: 0 0 10px;
                line-height: normal;
                font-weight: 400;
            }
            .password{
                @include media-query.breakpoint(smallg) {
                    width: 100%;
                }
            }

        }
        .btn-default{
            margin: 10px 0 0;
        }
    }
    &__formgroup{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        @include media-query.breakpoint(smallg) {
            gap: 10px;
            flex-direction: row;
        }
        .form-controls{
            width: 100%;
            @include media-query.breakpoint(smallg) {
                width: 49%;
            }
            &__full-width {
                width: 100%;
            }
        }
        .password{
            position: relative;
            width: 100%;
            display: block;
            @include media-query.breakpoint(smallg) {
                width: 49%;
            }
            .form-controls{
                -webkit-text-security: disc;
                @include media-query.breakpoint(smallg) {
                    width: 100%;
                }
            }
            &.showPass{
                .form-controls{
                    -webkit-text-security: none;
                }
                .show{
                    display: none;
                }
                .hide{
                    display: block;
                } 
            }
        }
    }
    .eye{
        position: absolute;
        right: 10px;
        top: 23px;
    }
    &__rightbar{
        background: #EAE6FE;
        &--dashboard{
            background: #EAE6FE url(../images/admin-dashboard.png) no-repeat center right;
            background-size: cover;
        }
    }
    &__dlist{
        @include media-query.breakpoint(medium) {
            justify-content: flex-start;
        }
    }
    &__ditems{
        gap: 20px;
    }
    &__ditemfull{
        max-width: 100%;
        width: 100%;
        padding: 20px;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
        align-items: center;
        @include media-query.breakpoint(medium) {
            max-width: 96%;
            height: 104px;
            flex-wrap: nowrap;
            gap: 0;
            justify-content: space-between;
        }
        .part-content{
            display: flex;
            gap: 10px;
            flex-direction: column;
            p{
                color: #0B0B0B;
                font-size: 16px;
                a{
                    color: #0B0B0B;
                    &:hover{
                        color: #C67EFC;
                    }
                }
            }
        }
        
    }
    &__ditemauto{
        max-width: fit-content;
        width: auto;
        gap: 24px;
        padding: 20px;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        @include media-query.breakpoint(medium) {
            height: auto;
            padding: 5px;
            justify-content: space-between;
        }
    }
    .admin-part-btn{
        background-color: #C67EFC;
        border-radius: 68px;
        border: 1px solid #C67EFC;
        color: #fff;
        padding: 8px 15px;
        font-size: 14px;
        display: block;
        @include media-query.breakpoint(smallg) {
            padding: 9px 32px;
            font-size: 18px;
        }
        &:hover{
            background-color: transparent;
            color: #C67EFC;
        }
    }
    &__rightbar{
        padding: 20px;
        @include media-query.breakpoint(smallg) {
            padding: 30px 30px;
        }
    }
    .form-group{
        width: 100%;
        margin: 0;
        @include media-query.breakpoint(smallg) {
            width: 49%;
        }    
        &--full{
            @include media-query.breakpoint(smallg) {
                width: 100%;
            }
        }
        label{
            display: none;
            color: #A7A0F1;
            font-size: 14px;
            margin: 0 0 4px;
            display: none;
        }
        .form-controls{
            @include media-query.breakpoint(smallg) {
                width: 100%;
            }
            &--textarea{
                height: 110px;
                resize: none;
            }

        }
    }
    form{
        // padding: 18px;
        &.edit{
            background-color: #ffff;
            border-radius: 8px;
            label{
                display: block;
            }
            .form-controls{
                padding: 0;
                height: 35px;
            }
        }
    }
    .edit-spacing{
        background-color: #ffff;
        &.edit{
            background-color: #ffff;
            border-radius: 8px;
            label{
                display: block;
            }
            .form-controls{
                padding: 0;
                height: 35px;
            }
        }
    }
    &__addedtopslot{
        background: #aba4f0;
        background: linear-gradient(90deg, #ab9fec 0, #9d71d2 12%, #aaa2f0 30%, #aba4f0 60%);
        h3{
            font-size: 22px;
            color: #fff;
            font-weight: 600;
            margin: 0 0 10px;
        }
        p{
            font-size: 16px;
            color: #fff;
        }
    }
    &__rightbody{
        max-width: 100%;
    }
    &__addedtopslotbtn{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 57px;
        border-radius: 68px;
        border: 1px solid #fff;
        color: #fff;
        font-size: 18px;
        &:hover{
            color: #8F88D5;
            background-color: #fff;
        }
    }
    &__tableheading{
        font-size: 16px;
        color: #033C81;
        font-size: 16px;
        font-weight: 600;
        margin: 0 0 10px;
    }
    &__table{
        thead{
            background-color: #fff;
        }
        th{
            font-size: 13px;
            color: #A7A0F1;
            padding: 9px 15px;
            width: 14%;
            font-weight: 600;
            &:first-child{
                border-radius: 8px 0 0 8px;
            }
            &:last-child{
                border-radius: 0 8px 8px 0;
            }
        }
        tbody{
            background-color: #fff;
            border-top: 4px solid #eae6fe;
            tr{
                &:first-child{
                    td{
                        &:first-child{
                            border-radius: 8px 0 0 0;
                        }
                        &:last-child{
                            border-radius: 0 8px 0 0;
                        }
                    }
                }
                &:last-child{
                    td{
                        &:first-child{
                            border-radius: 0 0 0 8px;
                        }
                        &:last-child{
                            border-radius: 0 0 8px 0;
                        }  
                    }
                }
            }
        }
        td{
            font-size: 14px;
            color: #000;
            padding: 9px 15px;
            width: 14%;
            line-height: normal;
            vertical-align: middle;
            img{
                border-radius: 5px;
            }
        }
    }
    &__apartbtnback{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 149px;
        height: 49px;
        border-radius: 68px;
        border: 1px solid #C67EFC;
        margin: 0 0 20px;
        color: #C67EFC;
        &:hover{
            color: #C67EFC;
            background-color: #fff;
        }
    }
    &__textarea{
        height: 170px;
        resize: none;
        margin: 0 0 10px;
        width: 99%;
    }
    &__fileupload{
        position: relative;
        width: 100%;
        @include media-query.breakpoint(smallg) {
            width: 49%;
        }
        input[type="file"] {
            display: none;
        }
        .form-controls{
            color: #C67EFC;
            font-size: 14px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            cursor: pointer;
            @include media-query.breakpoint(smallg) {
                width: 100%;
            }
        }
        .fileuploadimage{
            width: 100%;
        }
        .fileuploadclient{
            width: 186px;
            height: 194px;
            border-radius: 16px;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 10px;
            font-size: 14px;
            color: #555555;
            cursor: pointer;
        }
    }
    &__holobtn{
        border-radius: 68px;
        border: 1px solid #C67EFC;
        background: transparent;
        color: #C67EFC;
        font-size: 18px;
    }
    &__clinicarea{
        padding: 0;
        padding: 20px;
        border-radius: 8px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        gap: 20px;
        flex-wrap: wrap;
        @include media-query.breakpoint(smallg) {
            justify-content: space-between;
            gap: 0;
        }
    }
    &__leftclient{
        width: 100%;
        @include media-query.breakpoint(smallg) {
            width: 39%;
        }
        img{
            border-radius: 16px;
        }
    }
    &__rightclient{
        width: 100%;
        @include media-query.breakpoint(smallg) {
            width: 55%;
        }
        label{
            color: #a7a0f1;
        }
        .form-group{
            @include media-query.breakpoint(smallg) {
                width: 48%;
            }
            &--full{
                @include media-query.breakpoint(smallg) {
                    width: 100%;
                } 
            }
        }
        .form-controls{
            &:disabled{
                margin: 0 0 10px;
            }
        }
        .o-admin__textarea{
            width: 100%;
            min-height: 60px;
            height: fit-content;
        }
    }
    &__partareaform{
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        @include media-query.breakpoint(medium) {
            flex-wrap: nowrap;
            gap: 30px;
        }
        .part138{
            width: 138px;
            cursor: pointer;
            .o-admin__fileupload{
                @include media-query.breakpoint(smallg) {
                    width: 100%;
                }
            }
        }
        .part186{
            width: 186px;
            cursor: pointer;
            .o-admin__fileupload{
                @include media-query.breakpoint(smallg) {
                    width: 100%;
                }
            }
        }
        .part{
            flex: 1;
        }
    }
    .show{
        display: block;
    }
    .hide{
        display: none;
    }
}
br{
    display: none;
    @include media-query.breakpoint(smallg) {
        display: block;
    }
}
a{
    transition: all .5s ease-out;
    outline: none;
}
select{
    &:disabled{
        background: none;
    }
}

.o-page404{
    $o-page404 : &;
    height: -moz-calc(100vh - 83px);
    height: -webkit-calc(100vh - 83px);
    height: calc(100vh - 83px);
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    &__contentarea{
        padding: 40px 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column;
    }
    &__imagetext{
        position: relative;
        display: inline-flex;
        height: 235px;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 50px;
        &--something-wrong{
            height: auto;
            margin-bottom: 60px;
        }
        h1{
            position: relative;
            font-size: 148px;
            color: #000;
            line-height: normal;
            font-weight: 400;
            z-index: 1;
        }
        img{
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            margin: auto;
        }
    }
    &__alttext{
        font-size: 36px;
        color: #000;
        line-height: normal;
        text-align: center;
        margin-bottom: 40px;
        font-weight: 400;
    }
    &__btn{
        display: inline-flex;
        border-radius: 68px;
        background-color: #fff;
        color: #C67EFC;
        font-size: 18px;
        line-height: normal;
        padding: 13px 42px;
        text-transform: capitalize;
        &:hover{
            background-color: #C67EFC;
            color: #fff;
        }
    }
}

.admin-part-btn{
    background-color: #C67EFC;
    border-radius: 68px;
    border: 1px solid #C67EFC;
    color: #fff;
    padding: 8px 15px;
    font-size: 14px;
    display: block;
    @include media-query.breakpoint(smallg) {
        padding: 9px 32px;
        font-size: 18px;
    }
    &:hover{
        background-color: transparent;
        color: #C67EFC;
    }
}

.profle-right-box {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
}

.custom-modal-style {
    border-radius: 8px;
    background-color: #eae6fe;
    .modal-header {
        padding: 30px 30px 5px 30px;
    }
    .modal-body {
        padding: 5px 30px 30px 30px;
    }
}

.custom-form-style {
    textarea {
        width: 100%;
        margin-bottom: functions.torem(10px);
        padding: 12px;
        color: #555555;
        background-color: colors.$white;
        border-radius: 8px;
    }
}

.clipboard-copied 
{
    display: none;
    text-align: center;
    background-color: #008600;
    padding: 10px 15px;
    color: #fff;
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    margin-top: 10px;

}

.form-group-invert {
    label {
        display: none;
    }
    textarea {
        height: 100px;
        width: 100%;
        margin-bottom: functions.torem(20px);
        padding: 12px;
        color: #555555;
        background-color: colors.$white;
        border-radius: 8px;
    }
}

.btn-edit,
.btn-delete {
    padding: 5px 10px;
}

.o-top__addedtopslotbtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 57px;
    border-radius: 68px;
    border: 1px solid #fff;
    color: #fff;
    font-size: 18px;
    background-color: transparent;
    &:hover{
        color: #8F88D5;
        background-color: #fff;
    }
}

.cke_notification_message {
    display: none;
}
.cke_notification {
    display: none;
}

.cke_contents {
    height: 100px !important;
}

.doctor-profile-form {
    input:disabled {
        background-color: transparent;
    }
    textarea:disabled {
        background-color: transparent;
    }
    select:disabled option {
        background-color: transparent;
    }
}

.patient-select-treatment 
{
    position: absolute;
    top: 30px;
    background-color: #8d1ee4c4;
    color: #fff;
    left: 0;
    padding: 8px 15px;
    font-size: 16px;
    line-height: 16px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    &:hover {
        background-color: #420074;
        color: #fff;
    }
}

.save-anchore
{
    display: flex;
    align-items: center;
    justify-content: center;
    i {
        margin-right: 5px;
    }
}